import { gql } from '.';
import { CART_FIELDS, PRODUCT_FIELDS } from './fragments';

export const GET_PRODUCT_BY_ID = gql`
    query ProductQuery ($id: ID, $handle: String) {
        product(id: $id, handle: $handle) {
            ...ProductFields
        }
    }
    ${PRODUCT_FIELDS}
`;

export const GET_CART = gql`
    query CartQuery ($id: ID!) {
       cart(id: $id) {
            ...CartFields
        }
    }
    ${CART_FIELDS}
`;

export const CREATE_CART = gql`
    mutation CreateCartMutation {
        cartCreate {
            cart {
                ...CartFields
            }
            userErrors {
                field
                message
            }
        }
    }
    ${CART_FIELDS}
`;
