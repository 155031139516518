import { createStorefrontApiClient } from '@shopify/storefront-api-client';
import { extractNodes, gql } from '.';
import { ARTICLE_FIELDS, CART_FIELDS } from './fragments';
import { CREATE_CART, GET_CART, GET_PRODUCT_BY_ID } from './queries';
import { LineItemInterface } from './types';

interface QueryVariablesInterface {
    numArticles: number;
    before?: string | null;
    after?: string | null;
}

export const client = createStorefrontApiClient({
    storeDomain: process.env.GATSBY_SHOP_URL || 'bim-3000.myshopify.com',
    apiVersion: '2024-04',
    publicAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN || '',
});

export const fetchShopifyArticles = async ({
    numArticles = 20,
    before = undefined,
    after = undefined,
}: {
    numArticles: number;
    before?: string | undefined;
    after?: string | undefined;
}) => {
    const query = gql`
        query($numArticles: Int!, $before: String, $after: String) {
            articles(${before ? 'last' : 'first'}: $numArticles, reverse: true, sortKey: PUBLISHED_AT, before: $before, after: $after) {
                pageInfo { # Returns details about the current page of results
                    hasNextPage # Whether there are more results after this page
                    hasPreviousPage # Whether there are more results before this page
                }
                edges {
                    cursor
                    node {
                        ...ArticleFields
                    }
                }
            }
        }
        ${ARTICLE_FIELDS}
    `;

    const variables: QueryVariablesInterface = {
        numArticles: numArticles || 20,
        before: before || null,
        after: after || null,
    };

    const { data, errors } = await client.request(query, {
        variables,
    });

    if (errors) {
        return errors;
    }

    if (data) {
        return data;
    }

    return null;
};

export const fetchLatestShopifyArticle = async () => {
    const data = await fetchShopifyArticles({
        numArticles: 1,
    });

    if (data?.articles) {
        const [article] = extractNodes(data.articles);

        if (article) {
            return article;
        }
    }

    return null;
};

export const getProductById = async (id: string) => {
    const { data, errors } = await client.request(GET_PRODUCT_BY_ID, {
        variables: {
            id,
        },
    });

    if (!errors && data?.product) {
        const product = {
            ...data.product,
            variants: data.product.variants.nodes,
        };

        return product;
    }

    return null;
};

export const getCart = async (cartId: string) => {
    if (!cartId) {
        return null;
    }

    const { data, errors } = await client.request<{
        cart: {
            id: string;
            checkoutUrl: string;
            webUrl: string;
            lines: {
                nodes: LineItemInterface[];
            }
        }
    }>(GET_CART, {
        variables: {
            id: cartId,
        },
    });

    if (!errors && data?.cart) {
        const cart = {
            ...data.cart,
            lines: data.cart.lines.nodes,
        };
        return cart;
    }

    return null;
};

export const createCart = async () => {
    const { data, errors } = await client.request<{
        cartCreate: {
            cart: {
                id: string;
                checkoutUrl: string;
                webUrl: string;
                lines: {
                    nodes: LineItemInterface[];
                }
            }
        }
    }>(CREATE_CART);

    if (!errors && data?.cartCreate?.cart) {
        const cart = {
            ...data.cartCreate.cart,
            lines: data.cartCreate.cart.lines.nodes,
        };
        return cart;
    }

    return null;
};

export const addToCart = async (
    cartId: string,
    lines: Pick<LineItemInterface, 'quantity' | 'merchandiseId' | 'attributes'>[],
) => {
    const { data, errors } = await client.request<{
        cartLinesAdd: {
            cart: {
                id: string;
                checkoutUrl: string;
                webUrl: string;
                lines: {
                    nodes: LineItemInterface[];
                }
            }
        }
    }>(gql`
        #graphql
        mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
                cart {
                    ...CartFields
                }
                userErrors {
                    field
                    message
                }
            }
        }
        ${CART_FIELDS}
    `, {
        variables: {
            cartId,
            lines,
        },
    });

    if (!errors && data?.cartLinesAdd?.cart) {
        const cart = {
            ...data.cartLinesAdd.cart,
            lines: data.cartLinesAdd.cart.lines.nodes,
        };
        return cart;
    }

    return null;
};

export const removeFromCart = async (cartId: string, lineIds: string[]) => {
    const { data, errors } = await client.request<{
        cartLinesRemove: {
            cart: {
                id: string;
                checkoutUrl: string;
                webUrl: string;
                lines: {
                    nodes: LineItemInterface[];
                }
            }
        }
    }>(gql`
        #graphql
        mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
            cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                cart {
                    ...CartFields
                }
                userErrors {
                    field
                    message
                }
            }
        }
        ${CART_FIELDS}
    `, {
        variables: {
            cartId,
            lineIds,
        },
    });

    if (!errors && data?.cartLinesRemove?.cart) {
        const cart = {
            ...data.cartLinesRemove.cart,
            lines: data.cartLinesRemove.cart.lines.nodes,
        };
        return cart;
    }

    return null;
};

export const updateCart = async (
    cartId: string,
    lines: Pick<LineItemInterface, 'quantity' | 'id' | 'attributes'>[],
) => {
    const { data, errors } = await client.request<{
        cartLinesUpdate: {
            cart: {
                id: string;
                checkoutUrl: string;
                webUrl: string;
                lines: {
                    nodes: LineItemInterface[];
                }
            }
        }
    }>(gql`
        #graphql
        mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
            cartLinesUpdate(cartId: $cartId, lines: $lines) {
                cart {
                    ...CartFields
                }
                userErrors {
                    field
                    message
                }
            }
        }
        ${CART_FIELDS}
    `, {
        variables: {
            cartId,
            lines,
        },
    });

    if (!errors && data?.cartLinesUpdate?.cart) {
        const cart = {
            ...data.cartLinesUpdate.cart,
            lines: data.cartLinesUpdate.cart.lines.nodes,
        };
        return cart;
    }

    return null;
};
