import { gql } from '.';

export const ARTICLE_FIELDS = gql`
    fragment ArticleFields on Article {
        id
        handle
        title
        publishedAt
        colorScheme: metafield(namespace: "article", key: "color_scheme") {
            key
            value
        }
        content
        contentHtml
        image {
            originalSrc
            src: url(transform: {maxWidth: 1400, maxHeight: 1400})
            previewSrc: url(transform: {maxWidth: 1200, maxHeight: 900 crop: CENTER})
            width
            height
        }
        seo {
            title
            description
        }
        excerpt
        tags
    }
`;

export const PRODUCT_FIELDS = gql`
    fragment ProductFields on Product {
        id
        availableForSale
        description
        handle
        title
        options {
            name
            values
        }
        featuredImage {
            id
            url
            altText
        }
        priceRange {
            maxVariantPrice {
                amount
                currencyCode
            }
            minVariantPrice {
                amount
                currencyCode
            }
        }
        variants(first: 250) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            nodes {
                id
                title
                price {
                    amount
                    currencyCode
                }
                availableForSale
                sku
                image {
                    id
                    url
                    altText
                }
                selectedOptions {
                    name
                    value
                }
            }
        }
    }
`;

export const CART_FIELDS = gql`
    fragment CartFields on Cart {
        id
        checkoutUrl
        webUrl: checkoutUrl
        lines(first: 200) {
            nodes {
                id
                quantity
                attributes {
                    key
                    value
                }
                cost {
                    totalAmount {
                        amount
                        currencyCode
                    }
                }
                merchandise {
                    ...on ProductVariant {
                        id
                        title
                        product {
                            id
                            title
                            priceRange {
                                minVariantPrice {
                                    amount
                                    currencyCode
                                }
                                maxVariantPrice {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                        image {
                            url(transform: {maxWidth: 200})
                            altText
                            width
                            height
                        }
                        price {
                            currencyCode
                            amount
                        }
                        selectedOptions {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;
