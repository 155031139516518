import { CollectionInfoInterface, COLLECTIONS } from './constants';
import {
    CollectionInterface, GraqhQlNodesInterface, ProductInterface,
} from './types';

export const extractNodes = ({ edges }: GraqhQlNodesInterface<any>) => (
    edges?.map(({ node }) => node)
);

export const getCollectionThemeByCollectionId = (id: string) => {
    const haystack = Object.values(COLLECTIONS);
    const findInArray = (item: CollectionInfoInterface) => item.id === id;
    const needle = haystack?.find(findInArray);

    if (needle?.theme) {
        return needle.theme;
    }

    return null;
};

export const getProductMetaFieldValues = (product: ProductInterface | null) => product && (
    product.metafields?.reduce((
        acc: { [key: string]: string },
        item,
    ) => {
        if (item?.key) {
            acc[item.key] = item.value;
        }

        return acc;
    }, {})
);

export const getPreviewCollection = (collection: CollectionInterface, limit: number) => {
    const products = collection?.products || [];
    const hasMoreThanPreview = products.length > limit;

    return {
        ...collection,
        products: [
            ...hasMoreThanPreview
                ? products.slice(0, limit)
                : products,
        ],
    };
};

export const getCollectionLink = (collection: CollectionInterface) => `/${collection.handle}`;
export const getProductLink = (product: ProductInterface) => `/product/${product.handle}`;
export const gql = String.raw;
