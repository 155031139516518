import React, { useState, useEffect } from 'react';
import {
    addToCart, client, createCart, getCart,
    removeFromCart,
    updateCart,
} from '~/utils/api';
import {
    CartInterface, StoreContextInterface, StoreInterface,
} from '~/utils/types';

const initialStoreState: StoreInterface = {
    client,
    adding: false,
    cart: { lines: [] },
    products: [],
    shop: {},
};

export const StoreContext = React.createContext<StoreContextInterface>({
    store: initialStoreState,
});

const ShopifyStoreProvider = ({ children }: { children: any }) => {
    const [store, updateStore] = useState(initialStoreState);

    let isRemoved = false;

    useEffect(() => {
        const setCheckoutInState = (cart: CartInterface | null) => {
            if (cart?.id) {
                localStorage.setItem('shopify_cart_id', `${cart.id}`);
            }

            updateStore((prevState: any) => ({
                ...prevState,
                cart,
            }));
        };

        const initializeCheckout = async () => {
            // Check for an existing cart.
            const existingCartId = localStorage.getItem('shopify_cart_id');

            if (existingCartId) {
                try {
                    const cart = await getCart(existingCartId);
                    // Make sure this cart hasn’t already been purchased.
                    if (!isRemoved && cart?.id) {
                        setCheckoutInState(cart as CartInterface);
                        return;
                    }
                } catch (e) {
                    localStorage.setItem('shopify_cart_id', '');
                }
            }

            const newCart = await createCart();

            if (!isRemoved && newCart?.id) {
                setCheckoutInState(newCart as CartInterface);
            }
        };

        initializeCheckout();
    }, [isRemoved]);

    useEffect(() => () => { isRemoved = true; }, []);

    const providerValue: StoreContextInterface = {
        store,
        addVariantToCart: async (variantId, quantity, customAttributes = []) => {
            if (!variantId || !quantity) {
                console.error('Both a size and quantity are required.');
                return;
            }

            const { cart } = store;
            const cartId = cart?.id;

            if (cartId) {
                updateStore((prevState) => ({ ...prevState, adding: true }));

                const lineItemsToUpdate = [
                    { merchandiseId: variantId, quantity, attributes: customAttributes },
                ];

                const newCart = await addToCart(cartId, lineItemsToUpdate);

                if (newCart) {
                    updateStore((prevState) => ({
                        ...prevState,
                        cart: newCart,
                        adding: false,
                    }));
                    return;
                }

                updateStore((prevState) => ({
                    ...prevState,
                    adding: false,
                }));
            }
        },

        removeLineItem: async (lineItemID: string) => {
            const { cart } = store;
            const cartId = cart?.id;

            if (!cartId) {
                return;
            }

            const newCart = await removeFromCart(cartId, [lineItemID]);

            if (newCart) {
                updateStore((prevState) => ({ ...prevState, cart: newCart }));
            }
        },

        updateLineItem: async (lineItemID, quantity, customAttributes = []) => {
            const { cart } = store;
            const cartId = cart?.id;

            if (!cartId) {
                return;
            }

            const lineItemsToUpdate = [
                { id: lineItemID, quantity, attributes: customAttributes },
            ];

            const newCart = await updateCart(cartId, lineItemsToUpdate);

            if (newCart) {
                updateStore((prevState) => ({ ...prevState, cart: newCart }));
            }
        },
    };

    return (
        <StoreContext.Provider
            value={providerValue}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default ShopifyStoreProvider;
